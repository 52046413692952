import React from "react"


const App = () => {
  return (<>
     <div>AR.js와 React.js 샘플</div>
      <a-scene
        vr-mode-ui="enabled: false"
        embedded
        arjs="sourceType: webcam;"
        renderer="logarithmicDepthBuffer: true;"
      >
        {/* AR.js Marker */}
        <a-marker preset="hiro">
          {/* AR 오브젝트 (박스) */}
          <a-box position="0 0.5 0" material="color: yellow;"></a-box>
        </a-marker>
        {/* 기본 AR.js 카메라 */}
        <a-entity camera></a-entity>
      </a-scene>
  </>)
}

export default App